<template>
  <div class="view-account container-fluid">
    <div class="row">
      <div class="col-lg-6 py-4">
        <div class="card">
          <h6>
            Minha conta
          </h6>

          <form @submit.prevent="submit">
            <form-group
              id="email"
              class="mt-3"
              type="email"
              label="E-mail"
              v-model="form.email"
              :errors="errors.email"
              placeholder="Seu e-mail"
              readonly
            />

            <form-group
              id="name"
              type="text"
              class="mt-3"
              label="Nome"
              v-model="form.name"
              :errors="errors.name"
              placeholder="Seu nome"
            />

            <form-group
              id="phone"
              type="text"
              class="mt-3"
              label="Telefone"
              v-model="form.phone"
              :errors="errors.phone"
              placeholder="Número de celular"
            />

            <div class="d-flex justify-content-end">
              <button
                type="submit"
                class="btn btn-primary px-4"
                :disabled="loading"
              >
                <loading :show="loading">
                  Salvar
                </loading>
              </button>
            </div>
          </form>
        </div>
      </div>

      <div class="col-lg-6 py-4">
        <div class="card">
          <change-password />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formFields } from "@/functions/helpers.js";
import ChangePassword from "@/components/account/ChangePassword";

export default {
  components: { ChangePassword },
  data() {
    return {
      loading: false,
      ...formFields(["name", "phone", "email"], {
        ...this.$user.user
      })
    };
  },
  methods: {
    submit() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("user/update", this.form)
        .then(() => {
          this.$message.success("Salvo com sucesso");
        })
        .catch(error => {
          this.errors = error.response.data;
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.view-account {
  .card {
    height: 100%;
    border-radius: 8px;
    padding: 16px 16px 24px;
    .btn {
      font-weight: 500;
      border-radius: 4px;
    }
    h6 {
      margin: 0;
      font-size: 18px;
      font-weight: 500;
    }
    .form-group {
      label {
        font-size: 12px;
        margin-bottom: 4px;
        color: #6b6e73;
      }
    }
  }
}
</style>
