<template>
  <div class="account-change-password">
    <h6>Alterar senha</h6>

    <form @submit.prevent="submit">
      <form-group
        class="mt-3"
        type="password"
        id="password_old"
        label="Senha atual"
        placeholder="Senha atual"
        v-model="form.password_old"
        :errors="errors.password_old"
      />

      <form-group
        class="mt-3"
        id="password"
        type="password"
        label="Nova senha"
        v-model="form.password"
        placeholder="Digite nova senha"
        :errors="errors.password"
      />

      <form-group
        class="mt-3"
        type="password"
        id="repeat-password"
        label="Confirmar senha"
        v-model="form.repeat_password"
        placeholder="Digite nova senha"
        :errors="errors.repeat_password"
      />

      <div class="d-flex justify-content-end">
        <button
          type="submit"
          class="btn btn-primary px-4"
          :disabled="!isValid || loading"
        >
          <loading :show="loading">Salvar</loading>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { formFields } from "@/functions/helpers.js";

export default {
  data() {
    return {
      loading: false,
      ...formFields(["password_old", "password", "repeat_password"])
    };
  },
  computed: {
    isValid() {
      return (
        this.form.password &&
        this.form.password_old &&
        this.form.repeat_password
      );
    }
  },
  methods: {
    submit() {
      if (this.loading) {
        return;
      }

      if (this.form.password !== this.form.repeat_password) {
        this.errors.repeat_password = [
          "Os valores informados não correspondem"
        ];
        return;
      }

      this.loading = true;
      let data = {
        password_old: this.form.password_old,
        password: this.form.password
      };
      this.$store
        .dispatch("user/updatePassword", data)
        .then(() => {
          this.$message.success("Senha alterada com sucesso!");
          this.form = { password: "", password_old: "", repeat_password: "" };
        })
        .catch(error => {
          this.errors = error.response.data;
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style lang="scss">
.show-password-icon {
  right: 12px !important;
  top: 32px !important;
}
.show-error-icon {
  display: none;
}
</style>
